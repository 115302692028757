import React from 'react'
import { ProjectsContainer } from "./styles";

const ProjectsPage = () => {
    return (
        <ProjectsContainer>
            <span>¯\_(ツ)_/¯</span>
        </ProjectsContainer>
    );
};

export default ProjectsPage;
